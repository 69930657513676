document.addEventListener('devkitLazyLoad-Swiper', function () {

    const pageNotFoundSliderEl = document.querySelector('.page-not-found-slider');

    if (!pageNotFoundSliderEl) {
        return;
    }


    // Create a new Swiper instance
    const pageNotFoundSlider = new Swiper(pageNotFoundSliderEl, {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,

        centeredSlides: true,
        effect: 'fade',

        fadeEffect: { crossFade: false },
        speed: 1000,
        autoplay: {
            delay: 4000,
        },
    
    });
    
    

});