function adjustInputWidths() {
    const inputs = document.querySelectorAll(".dynamic-input");

    if (!inputs) {
        return;
    }

    inputs.forEach(input => {
        const placeholderText = input.getAttribute("placeholder");

        // Create a temporary span element to measure placeholder text
        const span = document.createElement("span");
        span.style.visibility = "hidden";
        span.style.position = "absolute";
        span.style.whiteSpace = "nowrap";

        // Copy all relevant font styles from the input
        const inputStyles = window.getComputedStyle(input);
        const fontProperties = [
            'fontSize',
            'fontFamily',
            'fontWeight',
            'fontStyle',
            'fontVariant',
            'letterSpacing',
            'textTransform',
            'textDecoration',
            'lineHeight',
            'padding',
            'border',
            'boxSizing'
        ];
        span.style.whiteSpace = 'pre';

        fontProperties.forEach(prop => {
            span.style[prop] = inputStyles[prop];
        });

        if (input.value) {
            span.textContent = input.value;
        } else {
            span.textContent = placeholderText;
        }
        document.body.appendChild(span);



        // Adjust the input's width to match the placeholder's width
        input.style.width = `${span.offsetWidth}px`;

        // Clean up the temporary span
        document.body.removeChild(span);
    });
}

// Adjust widths on page load
document.addEventListener("DOMContentLoaded", adjustInputWidths);

setTimeout(() => {
    adjustInputWidths();
}, 300);

// Adjust widths on resize
window.addEventListener("resize", adjustInputWidths);
// Adjust input widths as user types
document.querySelectorAll(".dynamic-input").forEach(input => {
    input.addEventListener("input", adjustInputWidths);
});

document.addEventListener("DOMContentLoaded", function () {
    const contactFormSection = document.querySelector("section.contact-form");


    if (!contactFormSection) {
        return;
    }

    const contactForm = document.querySelector("section.contact-form form");
    const contactFormTitle = contactFormSection.querySelector(".title");
    const contactFormContent = contactFormSection.querySelector(".content");

    contactForm.addEventListener("submit", function (event) {
        event.preventDefault();
        // disable form submit
        contactForm.querySelector('button').setAttribute('disabled', 'disabled');
        devkitFormSubmit(contactForm).then(function (res) {
            if (res.success) {

                updateTitle();
                updateContent();
            } else {
                contactForm.querySelector('button').removeAttribute('disabled');
            }
        });


    });

    function updateTitle() {
        contactFormTitle.innerHTML = "We’ve got it.<br /> Time to get to work! &#128077;";
    }

    function updateContent() {
        const contactName = contactForm.querySelector('#contact-name').value;
        const contactCompany = contactForm.querySelector('#contact-company').value;
        const contactEmail = contactForm.querySelector('#contact-email').value;
        const contactNumber = contactForm.querySelector('#contact-number').value;
        const contactQuery = contactForm.querySelector('#contact-query').value;

        contactForm.innerHTML = `<p>Thanks for reaching out <span>${contactName}</span>.  We’ve always wanted to work with <span>${contactCompany}</span>.  We’ll get right back to you at <span>${contactEmail}</span> and we’ll try you on <span>${contactNumber}</span> to get things started. Thanks for letting us know about <span>${contactQuery}</span>, that’s really helpful. You’ll hear from us real soon!</p>`;
    }

    const inputs = document.querySelectorAll(".dynamic-input");

    inputs.forEach(input => {

        input.addEventListener("blur", function () {

            const contactName = contactForm.querySelector('#contact-name').value.split(' ')[0];

            if (!contactName) {
                return;
            }

            if (!input.value) {
                return;
            }

            // const popup = input.previousElementSibling;
            // const originalHTML = popup.innerHTML;

            // if (!popup.classList.contains("tooltip")) {
            //     return;
            // }

            // const popupContent = popup.querySelector("span");

            // popup.style.opacity = 1;
            // popupContent.textContent = contactName;

            // let index = 0;
            // const text = popup.textContent;
            // popup.textContent = '';

            // function typeWriter() {
            //     if (index < text.length) {
            //         popup.textContent += text.charAt(index);
            //         index++;
            //         setTimeout(typeWriter, 100);
            //     }
            // }

            // typeWriter();
            

            // setTimeout(() => {
            //     popup.style.opacity = 0;
            //     popup.innerHTML = originalHTML;

            // }, 5000);
            

        });
    });
});