function createTetrisAnimation(imageElement) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set the canvas size to match the image's natural dimensions
    canvas.width = imageElement.naturalWidth;
    canvas.height = imageElement.naturalHeight;
    canvas.classList = imageElement.classList;

    // Replace the image with the canvas
    imageElement.parentNode.replaceChild(canvas, imageElement);

    // Fixed number of rows and columns
    const columns = 3;
    const rows = 3;

    // Calculate consistent piece size
    const pieceWidth = canvas.width / columns;
    const pieceHeight = canvas.height / rows;

    const pieces = [];
    let currentPieceIndex = 0;
    let lastPieceTime = performance.now();
    const pieceInterval = 500; // milliseconds between starting each piece

    class Piece {
        constructor(targetX, targetY, imgX, imgY) {
            this.targetX = targetX;
            this.targetY = targetY;

            this.x = targetX;
            this.y = targetY - 400;
       
            this.imgX = imgX;
            this.imgY = imgY;
            this.speed = 20;
            this.landed = false;
            this.opacity = 0; // Start with 0 opacity
            this.started = false;
            this.startTime = 0;
        }

        update(currentTime) {
            if (this.started) {
                const elapsedTime = currentTime - this.startTime;
                const duration = 1000; // Animation duration in milliseconds

                // Update position if not landed
                if (!this.landed) {
                    this.y += this.speed;
                    if (this.y >= this.targetY) {
                        this.y = this.targetY;
                        this.landed = true;
                    }
                }

                // Update opacity based on elapsed time
                const progress = elapsedTime / duration;
                this.opacity = Math.min(progress, 1);
            }
        }

        draw() {
            ctx.globalAlpha = this.opacity; // Set the opacity
            ctx.drawImage(
                image,
                this.imgX, this.imgY,
                pieceWidth, pieceHeight, // Source size in the image
                this.x, this.y,
                pieceWidth, pieceHeight  // Destination size on the canvas
            );
            ctx.globalAlpha = 1; // Reset opacity for other drawings
        }
    }

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    const image = new Image();
    image.src = imageElement.src;

    image.onload = () => {
        // Create pieces starting from the bottom row
        for (let row = rows - 1; row >= 0; row--) { // Reverse row order
            const rowPieces = [];
            for (let col = 0; col < columns; col++) {
                const imgX = col * pieceWidth;
                const imgY = row * pieceHeight;
                const targetX = col * pieceWidth;
                const targetY = row * pieceHeight;

                rowPieces.push(new Piece(targetX, targetY, imgX, imgY));
            }
            shuffleArray(rowPieces);
            pieces.push(...rowPieces);
        }

        animate();
    };

    function animate() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const currentTime = performance.now();

        // Start next piece if enough time has passed
        if (currentPieceIndex < pieces.length && currentTime - lastPieceTime >= pieceInterval) {
            pieces[currentPieceIndex].started = true;
            pieces[currentPieceIndex].startTime = currentTime; // Set start time
            currentPieceIndex++;
            lastPieceTime = currentTime;
        }

        let allPiecesCompleted = true;

        pieces.forEach(piece => {
            if (piece.started) {
                piece.update(currentTime);
                piece.draw();
                if (piece.opacity < 1) {
                    allPiecesCompleted = false;
                }
            } else {
                allPiecesCompleted = false; // Not started yet
            }
        });

        if (!allPiecesCompleted) {
            requestAnimationFrame(animate);
        }
    }
}

document.addEventListener('DOMContentLoaded', function () {

    // Your existing code to select images and start the animation
    const footerImages = document.querySelectorAll('footer img');
    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0
    };
    
    const observerCallback = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                createTetrisAnimation(document.querySelector('.left-images .main'));
    
        
    
                setTimeout(() => {
                    createTetrisAnimation(document.querySelector('.right-images img.sub-lower'));
                }, 1500);
    
                setTimeout(() => {
                    createTetrisAnimation(document.querySelector('.right-images img.main'));
                    createTetrisAnimation(document.querySelector('.left-images .sub'));
                }, 3000);
    
                setTimeout(() => {
                    createTetrisAnimation(document.querySelector('.right-images img.sub'));
                }, 4500);
                observer.unobserve(entry.target);
            }
        });
    };
    
    const observer = new IntersectionObserver(observerCallback, observerOptions);
    
    
    observer.observe(document.querySelector('footer#site-footer'));
    
    


});


document.addEventListener('DOMContentLoaded', function () {
    const footerNewsletteFormEl = document.querySelector('footer .newsletter');

    if (!footerNewsletteFormEl) {
        return;
    }

    footerNewsletteFormEl.addEventListener("submit", function (event) {
        event.preventDefault();
        devkitFormSubmit(footerNewsletteFormEl).then(function (res) {
            if (res.success) {

                footerNewsletteFormEl.innerHTML = `<p>Thanks for subscribing! We’ll keep you in the loop with our latest news and updates.</p>`;
            }
        });


    });



});