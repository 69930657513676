function rollUpNumber(element, target, duration, delay = 100) {
    const wrapper = element.parentElement;
    const initial = 0; // Starting value
    const steps = Math.abs(target - initial); // Total steps
    const interval = duration / steps; // Interval between each increment
    let current = initial;

    function rollNextNumber() {
        if (current <= target) {
            // Increment current before creating the new number element
            current++;

            // Create a new number element
            const newNumber = document.createElement('span');
            newNumber.textContent = current;
            newNumber.style.transform = 'translateY(100%)';
            newNumber.style.position = 'absolute';
            newNumber.style.top = '0';
            newNumber.style.left = '0';
            newNumber.style.width = '100%';
            newNumber.style.textAlign = 'center';
            newNumber.style.transition = `transform ${interval}ms ease`;
            wrapper.appendChild(newNumber);

            // Trigger the rolling animation for the new number
            setTimeout(() => {
                newNumber.style.transform = 'translateY(0%)';
            }, 250);

            // Animate and remove the previous number
            if (wrapper.children.length > 1) {
                const previousNumber = wrapper.children[0];
                previousNumber.style.transition = `transform ${interval}ms ease`;
                previousNumber.style.transform = 'translateY(-100%)';

                // Remove the previous number after its transition ends
                previousNumber.addEventListener('transitionend', function handler() {
                    wrapper.removeChild(previousNumber);
                    previousNumber.removeEventListener('transitionend', handler);

                    // Proceed to the next number after the previous number has been removed
                    if (current < target) {
                        setTimeout(() => {
                            rollNextNumber();
                        }, interval); // Start the next number after the interval
                    }
                });
            } else {
                // If there's no previous number, proceed to the next number after the interval
                if (current <= target) {
                    setTimeout(() => {
                        rollNextNumber();
                    }, interval);
                }
            }
        }
    }

    setTimeout(rollNextNumber, delay);
}

// Select all elements with the .amount class
const amountElements = document.querySelectorAll('.amount, .amount-inner');

if (amountElements) {

    // Set the duration for the rolling animation (e.g., 2000ms or 2 seconds)
    const duration = 3000;

    // Start the rolling animations for each .amount element
    amountElements.forEach((amount) => {
        const targetValue = parseInt(amount.textContent, 10);
        rollUpNumber(amount, targetValue, duration);
    });

}


document.addEventListener('devkitLazyLoad-Swiper', function () {


    if (window.matchMedia('(max-width: 768px)').matches) {

       
        new Swiper(document.querySelector('.working-long-term .row.tac'), {
            slidesPerView: 2.3,
            spaceBetween: 0,
            loop: true,
            allowTouchMove: true,
            freeMode: true,
        });
    }
});
