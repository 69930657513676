history.scrollRestoration = "manual";

window.addEventListener('beforeunload', function(){
    window.scrollTo(0, 0);
});

document.addEventListener('DOMContentLoaded', function () {
 
    if (!document.querySelector('.hero-tag')) {
        return;
    }

    document.querySelectorAll('.hero-tag').forEach(element => {
        element.innerHTML = element.textContent.split(' ').map(word => {
            return `<span class="word">${word.split('').map(letter => {
                if (letter === ' ') {
                    return '<span class="space">&nbsp;</span>';
                }
                return `<span>${letter}</span>`;
            }).join('')}</span>`;
        }).join(' ');
    });



    const preloader = document.querySelector('.hero .preloader');


    const preloaderText = preloader.querySelector('span');

    const words = ['Fast', 'Bold', 'Alive', 'Tough', 'Driven', 'Brave', 'Clear', 'Swift', 'Focused'];


    setTimeout(function () {
        let currentWord = 0;

        // Change word every 300 ms
        let lastTime = 0;

        function updateWord(timestamp) {
            if (timestamp - lastTime >= 100) {
                preloaderText.innerHTML = words[currentWord];
                currentWord++;
                lastTime = timestamp;
            }
            if (currentWord < words.length) {
                requestAnimationFrame(updateWord);
            } else {
                document.querySelector('.hero').classList.add('loaded');
      
                setTimeout(function () {
                    document.body.classList.remove('no-overflow');
                }, 1000);
            }
        }
        requestAnimationFrame(updateWord);
    }, 100);
});