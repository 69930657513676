document.addEventListener('devkitLazyLoad-Swiper', function () {
    const servicesCarousels = document.querySelectorAll('.services-carousel .swiper');

    let serviceCount = 1;

    if (!servicesCarousels) {
        return;
    }

    servicesCarousels.forEach(function (servicesCarousel, index) {
        const slides = servicesCarousel.querySelectorAll('.swiper-slide');

        setTimeout(function () {
            new Swiper(servicesCarousel, {
                slidesPerView: 1.5,
                spaceBetween: 0,
                loop: true,
                allowTouchMove: false,
                autoplay: {
                    delay: 0,
                    disableOnInteraction: false,
                    reverseDirection: serviceCount % 2 !== 0,
                },
                speed: 12000,
                breakpoints: {
                    768: {
                        slidesPerView: 2.5,
                    },
                },
            });
            

            slides.forEach(function (slide) {
                const images = slide.querySelector('.images');
                const imgEls = images.querySelectorAll('img');

                if (!imgEls.length) {
                    return;
                }

                let intervalId;

                // Images follow mouse on move
                slide.addEventListener('mousemove', function (event) {
                    const rect = slide.getBoundingClientRect();
                    const x = event.clientX - rect.left - (images.clientWidth / 2);
                    const y = event.clientY - rect.top - (images.clientHeight / 2);

                    images.style.transform = `translate(${x}px, ${y}px)`;
                });

                let previousImageEls;

                slide.addEventListener('mouseenter', function () {
                    // Clear any existing interval before starting a new one
                    if (intervalId) {
                        clearInterval(intervalId);
                    }

                    imgEls.forEach(function (imgEl) {
                        imgEl.style.opacity = 0;
                    });

                    if (previousImageEls) {
                        previousImageEls.forEach(function (imgEl) {
                            imgEl.style.opacity = 0;
                        });
                    }

                    let currentIndex = 0;
                    intervalId = setInterval(function () {
                        imgEls.forEach(function (imgEl) {
                            imgEl.style.opacity = 0;
                        });
                        imgEls[currentIndex].style.opacity = 1;
                        currentIndex = (currentIndex + 1) % imgEls.length;
                    }, 600);
                });

                slide.addEventListener('mouseleave', function () {
                    previousImageEls = imgEls;
                    clearInterval(intervalId);
                    previousImageEls.forEach(function (imgEl) {
                        imgEl.style.opacity = 0;
                    });
                });
            });

            serviceCount++;
        }, index * 1000); // Adjust the delay as needed
    });
});
