document.addEventListener('DOMContentLoaded', function() {
    const accordHeaders = document.querySelectorAll('.accord-head');

    if (!accordHeaders) {
        return;
    }

    accordHeaders.forEach((accordHeader) => {
        accordHeader.addEventListener('click', () => {
            accordHeader.classList.toggle('active');
            const accordBody = accordHeader.closest('.accord').querySelector('.accord-body');
            if (accordHeader.classList.contains('active')) {
                accordBody.style.maxHeight = accordBody.scrollHeight + 'px';
            } else {
                accordBody.style.maxHeight = 0;
            }
        });
    });
});