document.addEventListener('devkitLazyLoad-Swiper', function () {
    

    const caseStudiesSliders = document.querySelectorAll('.case-study__slider');

    if (!caseStudiesSliders) {
        return;
    }

    caseStudiesSliders.forEach(function (slider) {
        // Create a new Swiper instance
        const swiperSlider = new Swiper(slider, {
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            autoplay: false
        });

        // update slide number
        // swiperSlider.on('slideChange', function () {
        //     updateSliderCurrent(this, slider);
        // });

        // on load update total slides
        swiperSlider.on('init', function () {
            updateSliderTotal(this, slider);
        });

    });

    const closeCaseStudies = document.querySelectorAll('.close-case-study');

    if (!closeCaseStudies) {
        return;
    }

    closeCaseStudies.forEach(function (closeCaseStudy) {
        closeCaseStudy.addEventListener('click', function (e) {
            e.preventDefault();
            const caseStudy = e.target.closest('.case-study');
            caseStudy.classList.remove('open');
        });
    });


    const openSidebars = document.querySelectorAll('.open-sidebar');

    if (!openSidebars) {
        return;
    }

    openSidebars.forEach(function (openSidebar) {
        openSideContent(openSidebar);
    });

    const sliderImages = document.querySelectorAll('.case-study__slider img');

    if (!sliderImages) {
        return;
    }

    sliderImages.forEach(function (sliderImage) {
        addImageTranslate(sliderImage);
    });
    
    

});

function openSideContent(openSidebar) {
    openSidebar.addEventListener('click', function (e) {
        e.preventDefault();
        const caseStudy = e.target.closest('.case-study');
        caseStudy.classList.toggle('open');
    });
}

function updateSliderTotal(self, slider) {
    const totalSlides = self.slides.length;
    const slideNumElement = slider.closest('.case-study').querySelector('.slide-num');
    slideNumElement.querySelector('.total').textContent = String(totalSlides).padStart(2, '0');
}

// function updateSliderCurrent(self, slider) {
//     const currentSlide = self.realIndex;
//     const slideNumElement = slider.closest('.case-study').querySelector('.slide-num');
//     slideNumElement.querySelector('.current').textContent = String(currentSlide + 1).padStart(2, '0');
// }

function addImageTranslate(sliderImage) {
    let entered = false;
        let isAnimating = false;
        let targetTransform = { x: -50, y: -50 };
        let currentTransform = { x: -50, y: -50 };
        let zoomDelayTimeout = null;
        let easingFactor = 0.02; // Slower initial easing
    
        // Smooth update function using requestAnimationFrame
        function animate() {
            if (!isAnimating) return;
    
            currentTransform.x += (targetTransform.x - currentTransform.x) * easingFactor;
            currentTransform.y += (targetTransform.y - currentTransform.y) * easingFactor;
    
            sliderImage.style.transform = `translate(${currentTransform.x}%, ${currentTransform.y}%)`;
    
            // Stop animation when the difference is negligible
            if (
                Math.abs(targetTransform.x - currentTransform.x) > 0.1 ||
                Math.abs(targetTransform.y - currentTransform.y) > 0.1
            ) {
                requestAnimationFrame(animate);
            } else {
                isAnimating = false; // Stop animation loop
                easingFactor = 0.1; // Restore normal easing after initial motion
            }
        }
    
        sliderImage.addEventListener('mouseenter', function () {
            if (zoomDelayTimeout) clearTimeout(zoomDelayTimeout);
    
            entered = false; // Temporarily disable movement during the zoom-in animation
            easingFactor = 0.01; // Slow easing for the initial motion
            zoomDelayTimeout = setTimeout(() => {
                entered = true; // Allow movement after the delay
                isAnimating = true;
                animate(); // Start animation loop
            }, 1000); // Delay duration matching the CSS growth animation
        });
    
        sliderImage.addEventListener('mousemove', function (e) {
    
            const rect = sliderImage.getBoundingClientRect();
            const x = e.clientX - rect.left; // Mouse X position within the container
            const y = e.clientY - rect.top; // Mouse Y position within the container
    
            const centerX = rect.width / 2;
            const centerY = rect.height / 2;
    
            // Calculate the offset percentage from the center
            const offsetX = (x - centerX) / centerX;
            const offsetY = (y - centerY) / centerY;
    
            // Define the maximum movement in percentage
            const maxOffset = 3;
    
            targetTransform.x = -50 + (-offsetX * maxOffset);
            targetTransform.y = -50 + (-offsetY * maxOffset);
    
            if (!isAnimating) {
                isAnimating = true;
                animate(); // Start animation loop
            }
        });
    
        sliderImage.addEventListener('mouseleave', function () {
            if (zoomDelayTimeout) clearTimeout(zoomDelayTimeout); // Clear any pending delay
    
            entered = false; // Disable movement
            targetTransform = { x: -50, y: -50 }; // Reset position
            easingFactor = 0.05; // Slow easing for the return motion
            if (!isAnimating) {
                isAnimating = true;
                animate(); // Ensure smooth return to center
            }
        });
}